@font-face {
	font-family:AppleSDGothicNeo;
	src:local(AppleSDGothicNeoR),
		url(./font/AppleSDGothicNeoR.eot?#iefix) format('embedded-opentype'),
		url(./font/AppleSDGothicNeoR.woff2) format('woff2'),
		url(./font/AppleSDGothicNeoR.woff) format('woff'),
		url(./font/AppleSDGothicNeoR.ttf) format('truetype');
		font-weight:normal;
}

@font-face {
	font-family:AppleSDGothicNeo;
	src:local(AppleSDGothicNeoB),
		url(./font/AppleSDGothicNeoB.eot?#iefix) format('embedded-opentype'),
		url(./font/AppleSDGothicNeoB.woff2) format('woff2'),
		url(./font/AppleSDGothicNeoB.woff) format('woff'),
		url(./font/AppleSDGothicNeoB.ttf) format('truetype');
		font-weight:bold;
}


*{font-family: "AppleSDGothicNeo", "�����ٸ����", sans-serif;}
